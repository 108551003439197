<template>
  <div class="text-center">
    <v-dialog persistent v-model="dialog" width="80%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn rounded color="primary ma-2" dark v-bind="attrs" v-on="on">
          Wallet Transaction
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Wallet Transaction
          <v-spacer />
          <v-btn @click="dialog1 = !dialog1" rounded color="success" dark>
            Offline Payout
          </v-btn>
          <v-btn
            @click="freezeWallet('freeze')"
            v-if="!rider.freezeWallet"
            rounded
            color="pink ma-2"
            dark
          >
            Freeze Wallet
          </v-btn>
          <v-btn
            @click="freezeWallet('unfreeze')"
            v-else
            rounded
            color="info ma-2"
            dark
          >
            Unfreeze Wallet
          </v-btn>
        </v-card-title>
        <v-progress-linear
          v-if="TableLoading"
          indeterminate
          color="cyan"
        ></v-progress-linear>
        <div class="display-1">
          Available Balance: {{ DisplayCurrency(rider.wallet[0].balance) }}
        </div>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Description</th>
                <th class="text-left">Amount</th>
                <th class="text-left">Balance</th>
                <th class="text-left">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in WalletTransaction" :key="item.name">
                <td>{{ item.description }}</td>
                <td>{{ DisplayCurrency(item.amount) }}</td>
                <td>{{ DisplayCurrency(item.balance) }}</td>
                <td>{{ DisplayTimeFormat(item.createdAt) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="ActivitiesTotalItems"
          ></v-pagination>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
        <div class="text-center">
          <v-dialog v-model="dialog1" width="500">
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Payout
                {{ DisplayCurrency(amount) }}
              </v-card-title>

              <v-card-text class="pa-10">
                <v-text-field
                  v-model="amount"
                  label="Enter Amount"
                  type="number"
                  filled
                ></v-text-field>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="pink" @click="RiderOfflinePayout()">
                  Proceed
                </v-btn>
                <v-btn color="primary" text @click="dialog1 = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import currencyFormatter from "currency-formatter";

export default {
  props: ["riderId", "rider"],
  data() {
    return {
      dialog: false,
      dialog1: false,
      notifications: false,
      sound: true,
      widgets: false,
      desserts: [],
      page: 1,
      Currpage: 1,
      amount: "",
    };
  },
  computed: {
    ActivitiesTotalItems() {
      let total = this.$store.getters.staffWalletTransactionTotalItems;
      return Math.ceil(total / 20);
    },
    WalletTransaction() {
      return this.$store.getters.staffWalletTransaction;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
  },
  methods: {
    DisplayTimeFormat(payload) {
      return moment(payload).format("DD MMM YYYY, LTS");
    },
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "NGN",
      });
    },
    freezeWallet(action) {
      let payload = {
        token: this.getToken,
        riderId: this.riderId,
        action: action,
      };
      this.$store.dispatch("freezeRiderWallet", payload);
      this.dialog = false;
    },
    RiderOfflinePayout() {
      console.log(this.rider.wallet[0]._id);
      let payload = {
        token: this.getToken,
        riderId: this.riderId,
        walletId: this.rider.wallet[0]._id,
        amount: this.amount,
      };
      this.$store.dispatch("RiderOfflinePayout", payload);
      this.dialog = false;
      this.dialog1 = false;
    },
  },
  watch: {
    dialog: function (val) {
      if (val == true) {
        let payload = {
          token: this.getToken,
          page: 1,
          riderId: this.riderId,
        };
        this.$store.dispatch("GetRiderWalletHistory", payload);
      } else {
        this.$store.commit("setStaffWalletTransaction", []);
        this.$store.commit("setStaffWalletTransactionTotalItems", 0);
      }
    },
    page: function (val) {
      if (this.page != this.Currpage) {
        this.Currpage = val;
        let payload = {
          page: this.page,
          token: this.getToken,
          riderId: this.riderId,
        };
        this.$store.dispatch("GetRiderWalletHistory", payload);
        return;
      }
    },
  },
};
</script>