<template>
  <div>
    <v-container>
      <v-data-table
        :headers="dessertHeaders"
        :items="getRiders"
        item-key="_id"
        show-expand
        :single-expand="true"
        hide-default-footer
        class="elevation-1"
        :loading="TableLoading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div class="display-1" v-if="item.FulfillmentCenter">
              Office: {{ item.FulfillmentCenter.locationName }}
            </div>
            <v-card-actions>
              <div>
                <v-img
                  :src="item.profileImage"
                  :lazy-src="`https://picsum.photos/10/6?image=200`"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  max-width="100"
                  max-height="100"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
              <v-spacer />
              <div class="ma-5">
                <ActivateAndDisableRider :rider="item" />
              </div>
            </v-card-actions>
            <v-card-actions>
              <v-spacer />
              <RiderWalletTransactions :rider="item" :riderId="item._id" />
              <TransferStaff :Rider="item" />
              <v-spacer />
              {{ item.null }}
            </v-card-actions>
          </td>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination v-model="page" :length="totalItems"></v-pagination>
      </div>
    </v-container>
  </div>
</template>
<script>
import moment from "moment";
import RiderWalletTransactions from "../components/RiderWalletTransactions.vue";
import TransferStaff from "../components/TransferRider.vue";
import ActivateAndDisableRider from "../components/ActivateAndDisableRider.vue";

export default {
  components: {
    TransferStaff,
    RiderWalletTransactions,
    ActivateAndDisableRider,
  },
  data() {
    return {
      page: 1,
      Currpage: 1,
      expanded: [],
      singleExpand: false,
      dessertHeaders: [
        {
          text: "First Name",
          align: "start",
          value: "personalInfo.firstName",
        },
        { text: "Last Name", value: "personalInfo.lastName" },
        { text: "PhoneNumber", value: "personalInfo.phoneNumber" },
      ],
      desserts: [],
    };
  },
  computed: {
    getRiders() {
      return this.$store.getters.getRiders;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
    totalItems() {
      let total = this.$store.getters.totalItems;
      return Math.ceil(total / 20);
    },
  },
  methods: {
    DisTime(payload) {
      return moment(payload).calendar();
    },
    DisplayTimeFormat(payload) {
      return moment(payload).format("DD MMM YYYY, LTS");
    },
    getAllRider() {
      let payload = {
        page: this.page,
        token: this.getToken,
      };
      this.$store.dispatch("getAllRider", payload);
    },
  },
  created() {
    this.getAllRider();
  },
  watch: {
    page: function (val) {
      if (this.page != this.Currpage) {
        this.Currpage = val;
        let payload = {
          page: this.page,
          token: this.getToken,
        };
        this.$store.dispatch("getAllRider", payload);
        return;
      }
    },
  },
};
</script>